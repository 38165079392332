import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="main">compareitt.com</p>
        <p className="sub">
          A place to compare products and find great deals. Coming soon.
        </p>
        <copy>
          Photo by{" "}
          <a href="https://unsplash.com/@izzyfisch_?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
            Isabella Fischer
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/photos/five-red-apples-on-white-surface-uSPjZzYwXO4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
            Unsplash
          </a>
        </copy>
      </header>
    </div>
  );
}

export default App;
